import axios from "axios"
import { useAdminOrders } from "medusa-react"


let baseURL = "http://localhost:9000"

// deprecated
if (process.env.GATSBY_STORE_URL) {
  baseURL = process.env.GATSBY_STORE_URL
}

// takes precedence over GATSBY_STORE_URL
if (process.env.GATSBY_MEDUSA_BACKEND_URL) {
  baseURL = process.env.GATSBY_MEDUSA_BACKEND_URL
}

const client = axios.create({ baseURL })

export default function medusaRequest(method, path = "", payload = {}) {
  const options = {
    method,
    withCredentials: true,
    url: path,
    data: payload,
    json: true,
  }
  return client(options)
}

export const multipartRequest = (path, payload) => {
  const options = {
    withCredentials: true,
    headers: {
      "content-type": "multipart/form-data",
    },
  }

  return client.post(path, payload, options)
}
type CustomSearch = {
  solderingService: string | string[] | null
  doesNotContain: string | string[] | null

}
const cleanQuery = (query: any) => {
  let customSearch: any = {}
  let cleanedQuery = { ...query }
  if (query.hasOwnProperty("solderingService")) {
    customSearch.solderingService = query.solderingService.map(value => value === 'True' ? true : false)
    delete cleanedQuery.solderingService
  }
  if (query.hasOwnProperty("requiresAction")) {
    customSearch.requiresAction = query.requiresAction.map(value => value === 'True' ? true : false)
    delete cleanedQuery.requiresAction
  }
  if (query.hasOwnProperty("doesNotContain")) {
    customSearch.doesNotContain = query.doesNotContain
    delete cleanedQuery.doesNotContain
  }
  if (query.hasOwnProperty("limit")) {
    cleanedQuery.limit = 50
  }
  return { cleanedQuery, customSearch }
}

const updateLockedBool = (lockedBool: boolean, newValue: boolean) => {
  console.log("new value", newValue)
  // return the new value for locked bool once bool is false it will always return false
  if (!lockedBool) {
    return newValue
  } else {
    return false
  }
}

export const customUseAdminOrders = (queryObject: any) => {
  const { cleanedQuery, customSearch } = cleanQuery(queryObject)
  const { orders, isLoading, count } = useAdminOrders(cleanedQuery)
  const lookForSolderingService = customSearch.hasOwnProperty("solderingService")
  const lookForRequiresAction = customSearch.hasOwnProperty("requiresAction")
  const removingOrdersWithItemsInIt = customSearch.hasOwnProperty("doesNotContain")
  const filteredOrders = orders ? orders.filter(order => {
    let canReturn = true
    if (lookForSolderingService) {
      if (customSearch.solderingService.length === 1 && customSearch.solderingService[0] === true) {
        canReturn = updateLockedBool(canReturn, order.metadata.solderingService === true)
      }
      if (customSearch.solderingService.length === 1 && customSearch.solderingService[0] === false) {
        canReturn = updateLockedBool(canReturn, order.metadata.solderingService === undefined)
      }
    }
    if (lookForRequiresAction) {
      if (customSearch.requiresAction.length === 1 && customSearch.requiresAction[0] === true) {
        canReturn = updateLockedBool(canReturn, order.metadata.status === "requires_action")

      }
      if (customSearch.requiresAction.length === 1 && customSearch.requiresAction[0] === false) {
        canReturn = updateLockedBool(canReturn, (order.metadata.status === undefined || order.metadata.status !== "requires_action"))
      }

    }

    if (removingOrdersWithItemsInIt) {
      const containsBadId = order.items.find(item => customSearch.doesNotContain.includes(item.variant_id))
      if (containsBadId) {
        canReturn = updateLockedBool(canReturn, false)
      }
    }
    return canReturn
  }) : orders
  return { orders: filteredOrders, isLoading, count }
}

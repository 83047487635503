import clsx from "clsx"
import React from "react"

type SpinnerProps = {
  size?: "large" | "medium" | "small"
  variant?: "primary" | "secondary"
  withText?: boolean
}

const Spinner: React.FC<SpinnerProps> = ({
  size = "large",
  variant = "primary",
  withText
}) => {
  if (withText) {
    return (
      <div aria-label="Loading..." role="status" className="flex items-center space-x-2">
        <svg className="h-6 w-6 animate-spin stroke-gray-500" viewBox="0 0 256 256">
          <line x1="128" y1="32" x2="128" y2="64" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
          <line
            x1="195.9"
            y1="60.1"
            x2="173.3"
            y2="82.7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></line>
          <line x1="224" y1="128" x2="192" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
          <line
            x1="195.9"
            y1="195.9"
            x2="173.3"
            y2="173.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></line>
          <line x1="128" y1="224" x2="128" y2="192" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
          <line
            x1="60.1"
            y1="195.9"
            x2="82.7"
            y2="173.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></line>
          <line x1="32" y1="128" x2="64" y2="128" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line>
          <line
            x1="60.1"
            y1="60.1"
            x2="82.7"
            y2="82.7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"></line>
        </svg>
        <span className="text-xs font-medium text-gray-500">Loading...</span>
      </div>
    )
  }
  return (
    <div
      className={clsx(
        "flex items-center justify-center",
        { "h-[24px] w-[24px]": size === "large" },
        { "h-[20px] w-[20px]": size === "medium" },
        { "h-[16px] w-[16px]": size === "small" }
      )}
    >
      <div className="flex items-center justify-center relative w-full h-full">
        <div
          className={clsx(
            "animate-ring border-2 h-4/5 w-4/5 rounded-circle border-transparent",
            { "border-t-grey-0": variant === "primary" },
            { "border-t-violet-60": variant === "secondary" }
          )}
        />
      </div>
    </div>
  )
}

export default Spinner
